import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/stuJobApply',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/stuJobApply/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/stuJobApply',
    method: 'put',
    data
  })
}

export function getDtoById(id) {
  return request({
    url: 'api/stuJobApply/' + id,
    method: 'get'
  })
}

export function downloadPdf(id) {
  return request({
    url: 'api/stuJobApply/pdf/' + id,
    method: 'get',
    responseType: 'blob'
  })
}

export default { add, edit, del, getDtoById, downloadPdf }
